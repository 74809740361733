import React from 'react';
import Layout from "../components/layout"
import '../styles/batch-calendar.css'
import {Form} from 'react-bootstrap'
import {graphql, Link} from 'gatsby';




export const data = graphql`
   query {
        allCourseDataset {
           edges {
              node {
                  Id
                  Name
                  SlugURL
                  ThumbnailURL
                  Duration
                  Description
              }
           }
        }
     }
`;

const batchCalendar = ({data}) => {
   
   
   const course = data.allCourseDataset.edges;

   
    return (
        <Layout>
            <div className="batchCLOuter">
              <div className="container">
                 <h2>Batch Calendar</h2> 
                 <div className="row mt-5">
                     <div className="col-md-3">
                        <Form.Group controlId="course-mode">
                        <Form.Label>Course Mode</Form.Label>
                        <Form.Control name="course-mode" as="select">
                            <option value=""> -- select -- </option>
                            <option value="online"> Online </option>
                        </Form.Control>
                        </Form.Group>
                     </div>
                     <div className="col-md-3">   
                        <Form.Group controlId="country">
                        <Form.Label>Location</Form.Label>
                        <Form.Control name="country" as="select">
                            <option value="India"> India </option>
                            <option value="Malaysia"> Malaysia </option>
                            <option value="Singapore"> Singapore </option>
                            <option value="Singapore"> Singapore </option>
                            <option value="Others"> Others </option>
                        </Form.Control>
                        </Form.Group>
                     </div>
                     <div className="col-md-3">   
                        <Form.Group controlId="country">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" name="start-date"/>
                        </Form.Group>
                     </div>
                     <div className="col-md-3">   
                        <Form.Group controlId="country">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="date" name="end-date"/>
                        </Form.Group>
                     </div>
                 </div> 

                 <div className="row mt-5">
                    <div className="col-md-3 offset-md-9">
                       <Form.Group controlId="country">
                        <Form.Label>Sort By</Form.Label>
                        <Form.Control name="country" as="select">
                            <option value=""> Start Date : Ascending  </option>
                            <option value=""> Start Date : Descending  </option>
                            <option value=""> End Date : Ascending  </option>
                            <option value=""> End Date : Descending  </option>
                        </Form.Control>
                        </Form.Group>
                    </div>  
                 </div>

                 <div className="batchClListOuter">
                     {
                       course.map(item => {
                          return (
                           <div className="batchCLList">
                           <div className="batchCLList-heading">
                           <h4>{item.node.Name}</h4>
                           <p><b>Location :</b> over the web</p>
                           </div>  
                           <div className="batchCLList-timing">
                           <p><b>Dates</b> : June 18,19 2020</p>
                           <p><b>Timings</b> : 09:00 AM - 05:00 PM IST</p>
                           </div>
                           <div className="batchCLList-pricing">
                              <h4>INR 23000 <span>INR 25000</span></h4>  
                              <Link class="btn-small btn-primary btn" to="/">Enroll</Link>
                           </div>
                           </div>
                          )
                       })
                     }
                 </div>
                 
              </div> 
            </div>
        </Layout>
    );
};



export default batchCalendar;